<template>
  <section>
    <b-breadcrumb
      :items="breadcrumb"
    />

    <b-row>
      <b-col cols="12">
        <b-card-actions
          ref="filters"
          title="Filtros"
          class="form-filters"
          :action-close="false"
          :action-refresh="false"
          :action-collapse="true"
          @refresh="refreshStop('cardAction')"
        >
          <validation-observer ref="rulesAssignTo">
            <b-row>
              <b-col cols="3">
                <validation-provider
                  #default="{ errors }"
                  rules=""
                >
                  <b-form-group
                    label="De"
                    label-for="response_at_from"
                  >
                    <b-input-group>
                      <cleave
                        id="response_at_from"
                        v-model="runtimeFilters.response_at_from"
                        type="text"
                        class="form-control"
                        placeholder="DD/MM/YYYY"
                        autocomplete="off"
                        :raw="false"
                        show-decade-nav
                        :options="maskDate"
                      />
                      <b-input-group-append>
                        <b-form-datepicker
                          v-model="response_at_from_dp"
                          show-decade-nav
                          button-only
                          button-variant="outline-primary"
                          right
                          size="sm"
                          :date-format-options="{ day: '2-digit', month: '2-digit', year: 'numeric' }"
                          locale="pt-BR"
                          aria-controls="example-input"
                          @context="(ctx) => { runtimeFilters.response_at_from = (ctx.selectedDate) ? ctx.selectedFormatted : '' }"
                        />
                      </b-input-group-append>

                      <b-input-group-append v-if="runtimeFilters.response_at_from.length > 0">
                        <b-button
                          variant="outline-primary"
                          size="sm"
                          @click="runtimeFilters.response_at_from = ''"
                        >
                          <feather-icon icon="XIcon" />
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>

              <b-col cols="3">
                <validation-provider
                  #default="{ errors }"
                  rules=""
                >
                  <b-form-group
                    label="Até"
                    label-for="response_at_to"
                  >
                    <b-input-group>
                      <cleave
                        id="response_at_to"
                        v-model="runtimeFilters.response_at_to"
                        type="text"
                        class="form-control"
                        placeholder="DD/MM/YYYY"
                        autocomplete="off"
                        :raw="false"
                        show-decade-nav
                        :options="maskDate"
                      />
                      <b-input-group-append>
                        <b-form-datepicker
                          v-model="response_at_to_dp"
                          show-decade-nav
                          button-only
                          button-variant="outline-primary"
                          right
                          size="sm"
                          :date-format-options="{ day: '2-digit', month: '2-digit', year: 'numeric' }"
                          locale="pt-BR"
                          aria-controls="example-input"
                          @context="(ctx) => { runtimeFilters.response_at_to = (ctx.selectedDate) ? ctx.selectedFormatted : '' }"
                        />
                      </b-input-group-append>

                      <b-input-group-append v-if="runtimeFilters.response_at_to.length > 0">
                        <b-button
                          variant="outline-primary"
                          size="sm"
                          @click="runtimeFilters.response_at_to = ''"
                        >
                          <feather-icon icon="XIcon" />
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
          </validation-observer>

          <b-row class="text-right">
            <b-col
              cols="12"
              class="form-buttons"
            >
              <b-form-group
                label=""
                label-for="btn-buscar"
                style="margin-top: 20px"
              >
                <b-button
                  id="btn-buscar"
                  variant="primary"
                  @click="getRatings()"
                >
                  Buscar
                </b-button>

                <b-button
                  id="btn-limpar"
                  variant="outline-secondary"
                  @click="reset()"
                >
                  Limpar
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-actions>

        <b-card v-if="isSubmited">
          <b-row>
            <b-col>

              <b-table
                :busy="isLoading"
                fixed
                :fields="result_fields"
                :items="results"
                sort-icon-left
                no-local-sorting
                :sort-by.sync="filtersValues.sortBy"
                :sort-desc.sync="filtersValues.sortDesc"
              >
                <template #table-busy>
                  <div class="text-center table-spinner">
                    <b-spinner label="Loading..." />
                    <strong>Carregando...</strong>
                  </div>
                </template>

                <template #head(user)>
                  Usuário
                </template>

                <template #head(manager)>
                  Consultor
                </template>

                <template #head(scoreOver10)>
                  Pontuação sobre 10
                </template>

                <template #head(actions)>
                  Ações
                </template>

                <template #head(response_at)>
                  Avaliado em
                </template>

                <template #cell(scoreOver10)="row">
                  {{ row.item.response.scoreOver10 }}
                </template>

                <template #cell(response_at)="row">
                  {{ row.item.response_at | date }}
                </template>

                <template #cell(user)="row">
                  {{ row.item.user.name }}
                </template>

                <template #cell(manager)="row">
                  {{ row.item.account_manager.name }}
                </template>

                <template #cell(actions)="row">
                  <div class="text-nowrap text-center">
                    <b-button
                      variant="flat-primary"
                      title="Abrir perfil"
                      target="_blank"
                      :to="{ name: 'cms-user-form-update', params: { id: row.item.user_id } }"
                    >
                      <feather-icon
                        icon="UserIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </b-button>
                    <b-button
                      v-b-modal.modal-rating
                      variant="flat-primary"
                      title="Ver avaliação completa"
                      @click="setRating(row.item)"
                    >
                      <feather-icon
                        icon="FileTextIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </b-button>
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="6">
              <p>Mostrando {{ pages.from }} até {{ pages.to }} de {{ pages.total }} resultados</p>
            </b-col>

            <b-col cols="6">
              <!-- pagination nav -->
              <b-pagination-nav
                v-model="currentPage"
                :number-of-pages="pages.last_page"
                :link-gen="() => '#'"
                align="end"
              />
            </b-col>
          </b-row>

        </b-card>
      </b-col>
    </b-row>

    <b-modal
      id="modal-rating"
      ref="modal-rating"
      ok-only
      ok-title="Fechar"
    >
      <div v-if="currentRating">
        <h4>Usuário</h4>
        <p>{{ currentRating.user.name }}</p>

        <h4>Consultor</h4>
        <p>{{ currentRating.account_manager.name }}</p>

        <h4>Score total possível</h4>
        <p>{{ currentRating.response.sumBestScores }}</p>

        <h4>Score alcançado</h4>
        <p>{{ currentRating.response.score }}</p>

        <h4>Score sobre 10</h4>
        <p>{{ currentRating.response.scoreOver10 }}</p>

        <br><br>
        <div
          v-for="(option, index) in currentRating.response.options"
          :key="index"
        >
          <div v-if="option.options">
            <h4>{{ option.label }}</h4>
            <div class="ml-2">
              <div
                v-for="(question, questionIndex) in option.options"
                :key="`question-${questionIndex}`"
              >
                <h5>{{ question.label }}</h5>
                <p>{{ question.score }} - {{ question.selected === null ? 'Deixou em branco' : question.selected }}</p>
              </div>
            </div>
          </div>
          <div v-else>
            <h4>{{ option.label }}</h4>
            <p class="ml-2">
              {{ option.score }} - {{ option.selected }}
            </p>
          </div>
        </div>
      </div>
    </b-modal>
  </section>
</template>

<script>
import {
  BBreadcrumb,
  BFormDatepicker,
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BRow,
  BSpinner,
  BPaginationNav,
  BTable,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Cleave from 'vue-cleave-component'
import { maskDate } from '@/utils/masks'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  months,
} from '@/utils/options'
import { regex } from '@validations'
import UserService from '@/services/userService'
import toast from '@/mixins/toast'

export default {
  components: {
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    BBreadcrumb,
    BButton,
    BCard,
    BCardActions,
    BCol,
    BFormGroup,
    BRow,
    BSpinner,
    BTable,
    BPaginationNav,
    Cleave,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [
    toast,
  ],
  data() {
    return {
      regex,

      breadcrumb: [
        {
          text: 'Dashboard',
          to: { name: 'cms-dashboard' },
        },
        {
          text: 'Relatórios',
          active: true,
        },
        {
          text: 'Avaliações',
          active: true,
        },
      ],
      response_at_from_dp: '',
      response_at_to_dp: '',
      filtersValues: {
        sortBy: 'id',
        sortDesc: true,
      },
      runtimeFilters: {
        response_at_from: '',
        response_at_to: '',
      },
      months,
      isLoading: true,
      isSubmited: false,
      goalToDeleteWhenEditing: null,
      maskDate,
      pages: 1,
      currentPage: 1,
      results: [],
      result_fields: [],
      currentRating: null,
    }
  },
  computed: {
  },
  watch: {
    currentPage() {
      this.getRatings()
    },
  },
  mounted() {
    this.getRatings()
  },
  created() {
    this.result_fields = [
      { key: 'user', sortable: false },
      { key: 'manager', sortable: false },
      { key: 'scoreOver10', sortable: false },
      { key: 'response_at', sortable: false },
      { key: 'actions', sortable: true, thStyle: 'width: 150px' },
    ]
  },
  methods: {
    setRating(rating) {
      this.currentRating = rating
    },
    reset() {
      this.runtimeFilters = Object.fromEntries(Object.entries(this.runtimeFilters).map(item => {
        // eslint-disable-next-line no-param-reassign
        item[1] = ''
        return item
      }))
    },
    transformDate(date) {
      if (date.length === 10) {
        const from = date.split('/')
        return `${from[2]}-${from[1]}-${from[0]}`
      }
      return ''
    },
    getRatings() {
      this.$refs.rulesAssignTo.validate().then(success => {
        if (success) {
          this.isSubmited = true
          this.isLoading = true

          const dateFrom = this.runtimeFilters.response_at_from ? this.transformDate(this.runtimeFilters.response_at_from) : ''
          const dateTo = this.runtimeFilters.response_at_to ? this.transformDate(this.runtimeFilters.response_at_to) : ''

          UserService.listRatings(dateFrom, dateTo, this.currentPage)
            .then(response => {
              this.results = response.data.data.reports.data
              this.pages = { ...response.data.data.reports, data: null }
            })
            .catch(() => {
            })
            .finally(() => {
              this.isLoading = false
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-flatpicker.scss';

  .text-danger{
      font-weight: bold;
  }
</style>
